import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SearchPage from '../views/Search/Search.vue'
import Listing from '../views/Listing/Listing.vue'
import JourneyIntro from '../views/Intro/JourneyIntro.vue'
import Profile from '../views/Profile/Profile.vue'
import SavedSearches from '../views/Profile/SavedSearches.vue'
import Favorites from '../views/Profile/FavoritesComponent.vue'
import Tradeins from '../views/Profile/TradeinsComponent.vue'
import Orders from '../views/Profile/OrdersComponent.vue'
import Account from '../views/Profile/AccountComponent.vue'

export const ROUTES = {
  SEARCH: '/soegeresultater/:brand?/:something?',
  HOME: '/',
  LISTING: '/biler',
  ABOUT: '/about',
  INTRO: '/start',
  ME: '/min',
  SEARCHES: 'mine-soegninger',
  FAVORITES: 'favoritter',
  TRADEINS: 'byttebil',
  ORDERS: 'ordrer',
  ACCOUNT: 'konto',
  WHOLESALE: '/soegeresultater/engros',
  RETAIL: '/soegeresultater',
  BUYINGFLOW: '/ordrer'
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? { x: 0, y: 0 } : { top: 0 }
  },
  routes: [
    {
      path: ROUTES.ME,
      alias: ['/min-profil'],
      name: 'Profile',
      component: Profile,
      redirect: () => ({ name: 'Searches' }),
      children: [
        {
          path: ROUTES.SEARCHES,
          name: 'Searches',
          component: SavedSearches
        },
        {
          path: ROUTES.FAVORITES,
          name: 'Favorites',
          component: Favorites
        },
        {
          path: ROUTES.TRADEINS,
          name: 'Tradeins',
          component: Tradeins,
          meta: { requiresUser: true }
        },
        {
          path: ROUTES.ORDERS,
          name: 'Orders',
          component: Orders,
          meta: { requiresUser: true }
        },
        {
          path: ROUTES.ACCOUNT,
          name: 'Account',
          component: Account,
          meta: { requiresUser: true }
        }
      ]
    },
    {
      path: ROUTES.SEARCH,
      name: 'search',
      component: SearchPage,
      alias: ROUTES.SEARCH + '/'
    },
    {
      path: ROUTES.HOME,
      name: 'Home',
      component: HomeView
    },
    {
      path: `${ROUTES.LISTING}/:brand/:model/:id`,
      name: 'Details',
      component: Listing
    },
    {
      path: `${ROUTES.INTRO}/:id`,
      name: 'Intro',
      component: JourneyIntro
    },
    {
      path: `${ROUTES.SEARCH}/:somethingmore?`,
      name: 'ThirdPathDirect',
      redirect: () => ({ name: 'search' })
    },
    {
      path: `${ROUTES.BUYINGFLOW}/:id`,
      name: 'Order',
      component: () => import('../views/Order/Order.vue'),
      alias: ['/ordrer/:id'],
      meta: { hideFooter: true }
    }
  ]
})

router.beforeEach((to, from, next) => {
  Object.defineProperty(document, 'referrer', {
    configurable: true,
    get: () => {
      return from.name ? window.location.origin + from.path : ''
    }
  })
  next()
})

router.afterEach(() => window.seezSdk?.trackNavigation(window.location.href))

export default router
