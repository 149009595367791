<template>
  <div id="app">
    <HeaderComponent />
    <RouterView />
    <FooterComponent v-if="!hideFooter" />
  </div>
</template>

<script>
import { RouterView } from 'vue-router'
import HeaderComponent from './components/Header/Header.vue'
import FooterComponent from './components/Footer.vue'
import { prismicApi } from './lib/cms'
import { EVENTS, LEGAL_TEXT } from './lib/constants'
import carPlaceholder from './resources/car.webp'

export default {
  name: 'App',
  components: { HeaderComponent, FooterComponent, RouterView },
  mixins: [prismicApi],
  data() {
    return {
      SDKLoaded: false,
      user: null,
      media: null,
      dynamicData: null,
      language: 'da',
      hideFooter: false
    }
  },
  computed: {
    events() {
      return EVENTS
    },
    cardPlaceholder() {
      return `${window.origin}${carPlaceholder}`
    }
  },
  watch:{
    '$route'(to, from) {
      if (to.name !== 'Details' && from.path === '/') {
        localStorage.removeItem('price')
      }
    },
  },
  mounted() {
    this.loadCookiebot()
    this.loadSDK()
    this.enableTracking()
    this.hideFooter = this.$route.meta.hideFooter
  },
  methods: {
    async getDynamicContent(type, uid) {
      const document = await this.queryByUid(type, uid, 'da')
      this.dynamicData = document?.data

      return document
    },
    async loadSDK() {
      const components = ['search', 'favorites', 'saved-searches', 'carousel', 'trade-in', 'orders', 'trade-in']
      const promises = components.map(c => window.customElements.whenDefined('seez-sdk-' + c))
      await Promise.allSettled(promises)
      window.seezSdk.getCurrentUser(null, false).then(user => (this.user = user))
      this.SDKLoaded = true
    },
    //converts a string into something healthy for urls
    slugify(text) {
      return text
        ?.trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^\w]+/g, '-')
        .replace(/-+$/g, '')
        .toLowerCase()
    },
    setPageTitle(title, description) {
      const defaultDetails = {
        path: 'find-brugt-bil',
        title: 'Brugte biler til salg - Køb brugte biler på starmark.dk',
        description: 'Alle brugte biler fra Starmark er udvalgt med omhu og lever op til vores høje krav til kvalitet. Find din næste bil i Seez store bilunivers'
      }
      document.title = title ? `${title}` : defaultDetails.title
      document.head.querySelector('meta[name=description]').setAttribute('content', description ?? defaultDetails.description)
    },
    startTracking() {
      localStorage.setItem('cookiebot_consent', 'true')
      window.seezSdk.enableTracking(import.meta.env.VITE_SEGMENT_ID)
    },
    loadCookiebot() {
      if (import.meta.env.VITE_COOKIEBOT_ID == null || import.meta.env.VITE_ENV_LABEL === 'local') return
      const script = document.createElement('script')
      script.id = 'Cookiebot'
      script.src = 'https://consent.cookiebot.com/uc.js'
      script.dataset.cbid = import.meta.env.VITE_COOKIEBOT_ID
      script.dataset.blockingmode = 'auto'
      script.type = 'text/javascript'
      script.async = true

      document.head.appendChild(script)
    },
    enableTracking() {
      if (import.meta.env.VITE_ENV_LABEL === 'test') {
        this.startTracking()
        return
      }
      window.addEventListener('CookiebotOnDecline', () => {
        console.log('rejected')
      })

      window.addEventListener(
        'CookiebotOnAccept',
        () => {
          if (window.Cookiebot.consent.statistics) {
            this.startTracking()
          }
        },
        false
      )
    },
    trackEvent(e) {
      const [[eventName, properties]] = e.detail
      this.track(eventName, properties)
    },
    track(eventKey, properties, vehicleData) {
      return window.seezSdk.track(eventKey, properties, vehicleData)
    },
    formatNumber(number, decimals, currency) {
      if (number == null) return ''
      const options = {}
      if (decimals != null) Object.assign(options, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
      if (currency != null) Object.assign(options, { style: 'currency', currency, currencyDisplay: 'narrowSymbol' })
      return new Intl.NumberFormat(this.language, options).format(parseFloat(number))
    },
    login() {
      this.track(this.events.NAVIGATION, { name: 'login' })
      window.seezSdk.showLogin(null, true, {
        branding: '<div class="branding">&nbsp;</div>',
        subtitle: 'Bekræft din email adresse, så du kan gemme dine favoritsøgninger og få adgang til din konto.',
        terms: 'Jeg bekræfter at jeg have læst og accepteret Starmark vilkår for brug og privatlivspolitik.',
        marketing: `${LEGAL_TEXT} <a href="mailto:info@starmark.dk">info@starmark.dk</a>`,
        error: 'Du skal acceptere Starmark vilkår for brug og privatlivspolitik for at fortsætte'
      })

      // const tag = document.querySelector('seez-sdk-login')?.shadowRoot.querySelector('.customMarketingText')
      // if (tag) {
      //   tag.innerHTML = `${LEGAL_TEXT} <a href="mailto:info@starmark.dk">info@starmark.dk</a>`
      // }
      // document.querySelector('seez-sdk-login-form').style.zIndex = 5
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Corporate S';
  font-weight: 300;
  font-style: normal;
  src: url('/resources/fonts/CorporateSLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Corporate S';
  font-weight: 300;
  font-style: italic;
  src: url('/resources/fonts/CorporateSLightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Corporate S';
  font-weight: 400;
  font-style: normal;
  src: url('/resources/fonts/CorporateSRegular.woff2') format('woff2');
}

@font-face {
  font-family: 'Corporate S';
  font-weight: 400;
  font-style: italic;
  src: url('/resources/fonts/CorporateSItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Corporate S';
  font-weight: 500;
  font-style: normal;
  src: url('/resources/fonts/CorporateSDemi.woff2') format('woff2');
}

@font-face {
  font-family: 'Corporate S';
  font-weight: 500;
  font-style: italic;
  src: url('/resources/fonts/CorporateSDemiItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Corporate S';
  font-weight: 700;
  font-style: normal;
  src: url('/resources/fonts/CorporateSBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Corporate S';
  font-weight: 700;
  font-style: italic;
  src: url('/resources/fonts/CorporateSBoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Corporate A Condensed';
  font-weight: 400;
  font-style: normal;
  src: url('/resources/fonts/CorporateACondensedRegular.woff2') format('woff2');
}

:root {
  --max-width: 70rem;
  --max-width-header: 100rem;
  --content-side-padding: max(2rem, calc(54vw - var(--max-width) / 2));
  --header-side-padding: max(2rem, calc(54vw - var(--max-width-header) / 2));
  --font-family: 'Corporate S', sans-serif, 'Segoe UI', Tahoma, Geneva, Verdana !important;
  --font-heading: 'Corporate A Condensed', sans-serif, 'Segoe UI', Tahoma, Geneva, Verdana !important;
  --highlight: #5bc2f4;
  --black: #000000;
  --white: #ffffff;
  --gray: rgba(0, 0, 0, 0.15);
  --gray-20: #eeeeee;
  --light-gray: #f5f5f5;
  --gray: #b4b4b4;
  --dark-gray: #757575;
  --dark-gray-80: #545454;
  --dark-gray-90: #333333;
  --navy-blue: #003e99;
  --orange: #ffa200;
  --red: #ff0009;
  --green: #009a33;
}

html {
  overflow-x: hidden;
}

html,
body {
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  scroll-behavior: smooth;
  font-family: var(--font-family);
}

h1 {
  font-family: 'Corporate A Condensed';
}

@media print {
  html,
  body {
    overflow: hidden;
    height: 100vh;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

button {
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

a:-webkit-any-link {
  color: inherit;
}

#app {
  @media print {
    min-height: auto;
    grid-template-rows: 1fr;
  }
}

// start logout modal styles

seez-sdk-logout .seezModal {
  border-radius: 0;

  > button {
    &:focus {
      outline: 0;
    }
  }

  @media screen and (min-width: 48rem) {
    border-radius: 16px !important;
  }
  > .logoutModal {
    .main {
      border-radius: 12px !important;
      color: var(--white);
      &:focus {
        outline: 0;
      }
    }
    :last-child {
      border-radius: 12px !important;
      color: var(--black);
    }
  }
}

// end logout modal styles

// start login modal styles

seez-sdk-login-form {
  .seezLogin {
    --highlight: #5bc2f4;

    @media screen and (min-width: 50rem) {
      margin: -1rem;
    }

    .loginForm {
      max-width: 28rem;

      input,
      button,
      > .socialWrapper > div > button.social {
        border-radius: 0.75rem;
        font-family: var(--font-family);
      }
    }

    h1 {
      font-weight: normal;
      font-family: var(--font-heading);
    }

    p {
      font-size: 1rem;
      font-weight: 300;
    }
  }

  .branding {
    background-image: url('./resources/starmark-logo.svg');
    background-repeat: no-repeat;
    background-color: black;
    background-position: 50% 15%;
    border-radius: 0;
    width: 18rem;
    height: 100%;
  }

  [slot='terms'],
  [slot='marketing'] {
    display: inline;
    font-family: var(--font-family);

    a {
      color: black;
      font-weight: bold;
    }
  }
}

seez-sdk-logout button {
  font-family: var(--font-family) !important;
}

seez-sdk-active-order-cancellation .seezModalBackground > .seezModal > .cancelOrderDialog {
  .cta > button {
    border-radius: 12px;
    border: 1px solid #5bc2f4;
    font-family: var(--font-family);

    &:first-of-type {
      color: #5bc2f4;
    }

    &:last-of-type {
      background-color: #5bc2f4;
    }
  }
}
</style>
