<template>
  <div class="wholesaleInfoHeader">Afhentningsbiler sælges udelukkende til købere med et gyldigt CVR-nummer. Bilerne sælges uden klargøring, reklamation og garanti. <a href="/engros">Læs mere her.</a></div>
</template>
<script>
export default {
  name: 'WholesaleBanner'
}
</script>
<style lang="scss">
.wholesaleInfoHeader {
  background-color: #fece00;
  font-size: 0.875rem;
  padding: 0.875rem;
  text-align: center;
}
</style>
