<template>
  <div v-if="!details && loading" class="loadingBackground" />
  <div v-else class="listingContainer" data-test-id="listing_details_main_specs">
    <benefits-header class="benefitsHeaderWrapper" :on-hide="wholesale" />
    <WholesaleBanner v-if="wholesale" />
    <ErrorMessage class="errorWrapper" v-if="error" />
    <div class="layout">
      <div class="leftContent">
        <div v-if="details && !loading" class="listingDetails">
          <Image-grid @load-image="onHide()" :details="details" />
          <Specs :details="details" />
          <ToggleSpecsList :details="details" />
        </div>
      </div>
      <div class="rightContent">
        <div>
          <ListingInfo @show-test-drive-modal="showTestDriveModal = true" @show-lead-modal="popupLeadModal" @click-buy="clickBuy" @click-trade-in="clickTradeIn" @scroll-to="scrollTo" :details="details" :loan-calculation="loanCalculation" :wholesale="details.wholesalePrice?.value" :selected-price-type="priceType" />
        </div>
      </div>
    </div>
    <div v-if="details && !loading" class="bottomSession">
      <div v-if="details && !loading">
        <TradeInBanner v-if="!wholesale" @click-trade-in="clickTradeIn" />
        <VideoBanner v-if="!wholesale" @click-test-drive="showTestDriveModal = true" />
      </div>
      <span v-if="!wholesale">
        <Benefits />
        <div v-if="showSeezCalculator" class="calculator">
          <div>
            <seez-sdk-loan-calculator ref="calculator" currency="DKK" :listing-id="details.id" lg="da" :show-calculation-details="true" :expand-calculation-details="true" />
          </div>
        </div>
      </span>
      <div ref="related" class="related" :class="{ hidden: !relatedTotal }">
        <h2>{{ t['you_might_like'] }}</h2>
        <seez-sdk-carousel @track="e => $root.trackEvent(e)" :placeholder="$root.cardPlaceholder" :style="cardStyles" ref="carousel" :filters="handleFilters()" to="/biler/{brand}/{model}/{id}" @select="goToCar" @loaded="e => relatedLoaded(e)" @click-payment="e => clickPayment(e)" lg="da" />
        <router-link @click="$root.track($root.events.CTA_CLICK, { properties: { event_label: 'search_all_listing_page' } })" to="/soegeresultater" data-test-id="added_search_button">
          {{ t['browse_all_vehicles'] }}
        </router-link>
      </div>
      <div>
        <ModelsInfoBanner v-if="!wholesale" />
      </div>
    </div>
    <Brochure v-if="details && !loading" :details="details" :qr-code-url="url" />
    <Drawer :direction="'right'" :exist="true" ref="RightDrawer">
      <seez-sdk-trade-in :policy-url="policyUrl" ref="tradeIn" :listing="$route.params.id" :search-vehicle="licensePlateToSearch" @close-drawer="clickTradeIn" @on-click-view-tradeins="goToMyTradeins" lg="da" />
    </Drawer>
    <Loader v-if="loading" />
    <seez-sdk-test-drive-modal :policy-url="policyUrl" ref="testDriveModal" :listing-id="details.id" @click-test-drive="showTestDriveModal = true" @close="showTestDriveModal = false" v-if="showTestDriveModal" lg="da">
      <ModalInfoFooter :reference-number="details.referenceNumber" />
    </seez-sdk-test-drive-modal>
  </div>
</template>
<script>
import { querier } from '../../lib/querierMixin'
import WholesaleBanner from '../../components/WholesaleBanner.vue'
import ImageGrid from './ImageGrid.vue'
import ListingInfo from './ListingInfo.vue'
import Specs from './SpecsComponent.vue'
import ToggleSpecsList from './ToggleSpecsList.vue'
import BenefitsHeader from './BenefitsHeader.vue'
import TradeInBanner from './TradeInBanner.vue'
import VideoBanner from './VideoBanner.vue'
import Benefits from './BenefitsComponent.vue'
import Drawer from './DrawerComponent.vue'
import Brochure from './BrochureComponent.vue'
import ModalInfoFooter from './ModalInfoFooter.vue'
import ModelsInfoBanner from './ModelsInfoBanner.vue'
import Loader from '../../components/LoaderComponent.vue'
import ErrorMessage from './Error.vue'
import { LISTING } from '../../lib/constants'
import { ROUTES } from '../../router'
import { formatSpec } from '../../lib/helper'

export default {
  name: 'ListingPage',
  components: {
    WholesaleBanner,
    BenefitsHeader,
    ImageGrid,
    ListingInfo,
    TradeInBanner,
    ModelsInfoBanner,
    VideoBanner,
    Benefits,
    Specs,
    ToggleSpecsList,
    Drawer,
    Brochure,
    ModalInfoFooter,
    Loader,
    ErrorMessage
  },
  mixins: [querier],
  data() {
    return {
      details: null,
      loading: false,
      error: null,
      licensePlateToSearch: '',
      noRelated: false,
      url: window.location.href,
      showTestDriveModal: false,
      loanCalculation: null,
      relatedTotal: 0,
      priceType: ''
    }
  },
  computed: {
    privateLease() {
      return this.details?.leasePrice?.audience === 'private'
    },
    hasRetailPrice() {
      return this.details?.retailPrice?.value
    },
    showSeezCalculator() {
      const { retailPrice, leasePrice } = this.details
      if (retailPrice?.value && !leasePrice?.value) return true
      if (leasePrice?.value && !retailPrice?.value) return false
      return true
    },
    cardStyles() {
      return {
        '--card-height': this.wholesale ? '24.5rem' : '27.5rem',
        '--card-financing-display': this.wholesale ? 'none' : 'flex',
        '--card-leasing-display': this.wholesale ? 'none' : 'flex'
      }
    },
    policyUrl() {
      return '/persondata-politik'
    },
    t() {
      return LISTING
    },
    sameModelFilter() {
      return `?models=${this.details?.model?.id}`
    },
    relatedFilter() {
      if (this.$route.params.id == null) return null
      else {
        const relatedToParam = `related-to=${this.$route.params.id}`
        const pricesParam = `price-type=${this.$route.query['price-type']}`
        if (this.$route.query['price-type']) return `?${relatedToParam}&${pricesParam}`
        return `?${relatedToParam}`
      }
    },
    formatSpec() {
      return formatSpec
    },
    wholesale() {
      return this.details?.wholesalePrice?.value != null
    },
    hasLeasePrice() {
      return Boolean(this.details?.leasePrice?.value || this.details?.wholesaleLeasePrice?.value)
    }
  },
  watch: {
    'details': function (newVal) {
      if (newVal && !this.loading) {
        this.$nextTick(() => {
          this.handleLayoutChange()
        })
      }
    },
    'loading': function (newVal) {
      if (!newVal && this.details) {
        this.$nextTick(() => {
          this.handleLayoutChange()
        })
      }
    },
    '$route.params.id'() {
      this.loadDetails()
    },
    'onHide'() {
      this.loading = false
    },
    'showTestDriveModal'() {
      this.$root.track(this.$root.events.CTA_CLICK, { properties: { event_label: 'show_test_drive_modal' } })
    }
  },
  created() {
    //handle queryString lease from search and select filter
    // price = lease. Can be passed as retail or wholesale.
    const price = localStorage.getItem('price')
    if (price) {
      this.priceType = price
    }

    this.loadDetails()
    this.calculateLoan()
    const forceRefresh = null
    const track = false
    window.seezSdk.getCurrentUser(forceRefresh, track).then(r => {
      this.user = r

      if (this.$route.query['show-tradein']) {
        if (r) this.showTradeInOffer(parseInt(this.$route.query['show-tradein']))
        else {
          // this.$root.showLogin()
          window.seezSdk.showLogin(null, true, {
            branding: '<div class="branding">&nbsp;</div>'
          })
        }
      }
    })
  },
  beforeUnmount() {
    localStorage.removeItem('price')
  },
  methods: {
    handleFilters() {
      if (this.priceType === 'lease') return `?price-type=lease&related-to=${this.$route.params.id}`
      else if (this.noRelated) return this.sameModelFilter
      return this.relatedFilter
    },
    handleLayoutChange() {
      const desktopQuery = window.matchMedia('(min-width: 50rem)')
      const mobileQuery = window.matchMedia('(max-width: 50rem)')

      const rightContent = document.querySelector('.rightContent')
      const imageGrid = document.querySelector('.imageGrid')
      const layout = document.querySelector('.layout')

      const applyLayout = () => {
        if (mobileQuery.matches && rightContent && imageGrid) {
          // On mobile, move .rightContent after .imageGrid
          imageGrid.insertAdjacentElement('afterend', rightContent)
        } else if (desktopQuery.matches && rightContent && layout) {
          // On desktop, put .rightContent back to .layout
          layout.appendChild(rightContent)
        }
      }

      applyLayout()

      desktopQuery.addEventListener('change', applyLayout)
      mobileQuery.addEventListener('change', applyLayout)
    },
    removePriceTypeParam() {
      const priceType = this.$route.query['price-type']
      if (priceType === 'lease') {
        localStorage.setItem('price', priceType)
        this.priceType = priceType
      }
      const url = new URL(window.location.href)
      url.searchParams.delete('price-type')
      window.history.replaceState({}, '', url)
    },
    getModelSlug(listing) {
      const text = `${listing.model.name} ${listing.variant}`

      return this.$root.slugify(text)
    },
    clickPayment(e) {
      const selectedPaymentType = e.detail[0].type
      const listing = e.detail[0].listing

      if (selectedPaymentType === 'financing') {
        const brand = this.$root.slugify(listing.model.family.brand.name)
        const modelSlug = this.getModelSlug(listing)
        window.location = `/biler/${brand}/${modelSlug}/${listing.id}/#calc`
        return
      }
      this.popupLeadModal()
    },
    popupLeadModal() {
      window.seezSdk.showModal('seez-sdk-lead-form', { listing: this.$route.params.id }, true)
      this.$root.track(this.$root.events.CTA_CLICK, { properties: { event_label: 'show_lead_modal' } })
    },
    onHide() {
      this.hideSession = false
    },
    scrollTo(reference) {
      if (reference && this?.$refs[reference]) return this.$refs[reference].scrollIntoView({ behavior: 'smooth' })
      if (this.wholesale) return (window.location = 'https://www.starmark.dk/engros/')
      return this.$refs.calculator.scrollIntoView({ behavior: 'smooth' })
    },
    clickBuy() {
      this.$root.track(this.$root.events.ADD_TO_CART, {
        properties: { type: 'listing_page' },
        event_label: { car_id: this.details.id },
        listing_id: parseInt(this.details.id)
      })
      this.validation()
    },
    async calculateLoan() {
      this.loading = true
      try {
        const listingId = parseInt(this.$route.params.id)
        if (!(listingId > 0)) throw new Error('Invalid listing id: ' + this.$route.params.id)
        const result = await this.queryApi(`{loanCalculation(listingId:${listingId}, paymentTerms:96){ monthlyPayment disclaimer paymentTerms }}`)
        this.loanCalculation = result.loanCalculation
      } catch (error) {
        console.error(error)
        this.loanCalculation = null
      } finally {
        this.loading = false
      }
    },
    async validation() {
      const isReserved = this.details.state !== 'available'
      const isReservedByMe = this.details?.reservation?.reservedFor?.id?.toString() === this.currentUser?.id?.toString()
      this.loading = true
      const currentUser = await window.seezSdk.getCurrentUser()
      if (currentUser) {
        this.loading = true
        const { user } = await this.queryApi('{user{activeOrder{id, state, listingId}, orders {id, listingId, state}}}')
        this.loading = false

        if (this.details.reservedBy === currentUser.id) {
          const order = user.activeOrder ?? user.orders.find(o => o.listingId === this.details.id.toString() && o.state === 'confirmed')
          window.location = `${window.location.origin}${ROUTES.CHECKOUT}/?order=${order.id}`
          return
        } else if (user.activeOrder && !isReserved) {
          const decision = await window.seezSdk.confirmCancelOrder(user.activeOrder.listingId, this.$root.cardPlaceholder)
          if (decision === 'ResumeCurrent') window.location = `${window.location.origin}${ROUTES.CHECKOUT}/?order=${user.activeOrder.id}`
          if (decision === 'CancelAndStartNew') {
            this.loading = true
            await this.queryApi(`mutation {cancelOrder(orderId: ${user.activeOrder.id}) {id} }`)
            this.loading = false
            this.$router.push({ path: `${ROUTES.INTRO}/${this.$route.params.id}` })
          }
          return
        }
      }
      if (isReserved && !isReservedByMe) {
        window.seezSdk.showUnavailableListingModal('/soegeresultater/', this.$route.params.id, '/biler/{brand}/{model}/{id}', `/soegeresultater/?related-to=${this.$route.params.id}`)
        this.loading = false
      } else this.$router.push({ path: `${ROUTES.INTRO}/${this.$route.params.id}` })
    },
    async loadDetails() {
      this.loading = true
      this.error = null
      try {
        const query = `
          query listingDetails($listingId: ID) {
            listing(id: $listingId) {
              id name model {id name family { id name brand {id name}}} bodyType { name } targetSite { name logo brandingHighlightColor }
              variant year currency kilometrage dealership {id name description logoUrl financing {logo}}
              images color batterySize hp transmission {name} fuelType {id name} registrationDate state reservedBy reservation { reservedFor { id } }
              equipment { design comfort safety uncategorized} referenceNumber acceleration numAirbags numGears numCylinders
              weight loadCapacity maxAttachment numDoors fuelConsumption range description locatedAt { plainAddress }
              typeName leasePrice { value currency lease { duration downPayment residualValue type audience yearlyKmtrage }}
              wholesaleLeasePrice{ value currency lease { duration downPayment residualValue type audience }}
              retailPrice { value currency disclaimer } emiPrice { value currency } wholesalePrice { value currency}
              videos greenTax batteryConsumption topSpeed torque
            }
          }`
        const listingId = parseInt(this.$route.params.id)
        if (!(listingId > 0)) throw new Error('Invalid listing id: ' + this.$route.params.id)
        const result = await this.queryApi(query, { listingId })
        this.details = result.listing
        const listingSEO = this.getListingSEO()
        this.$root.setPageTitle(listingSEO.title, listingSEO.description)
      } catch (err) {
        console.error(err)
        this.error = err.message ?? err
      } finally {
        this.loading = false
        this.removePriceTypeParam()
      }
    },
    getPrice() {
      const d = this.details
      const priceValue = d.wholesalePrice?.value ?? d.retailPrice?.value ?? d.leasePrice?.value
      const priceString = this.$root.formatNumber(priceValue, 0, d.currency)
      const suffix = (d.wholesalePrice?.value ?? d.retailPrice?.value == null) ? ' / md' : ''
      return priceString + suffix
    },
    getListingSEO() {
      const price = this.getPrice()
      const modelVariant = `${this.details.model.name}  ${this.details.variant}`
      const brand = this.details.model.family.brand.name === 'Mercedes' ? 'Mercedes-Benz' : this.details.model.family.brand.name
      const title = `${price} | ${modelVariant} - ${this.details.color} | ${brand} | Starmark `
      const description = `Køb ${brand} ${modelVariant} fra ${this.details.year} i ${this.details.color} på Starmark ✓ Pris:${price} ✓ Se specifikationer og fotos online`
      return { title, description }
    },
    clickTradeIn(inputValue) {
      this.$root.track(this.$root.events.CTA_CLICK, { properties: { type: 'trade_in_listing_page' } })

      if (this.$refs.RightDrawer.active) {
        this.$refs.RightDrawer.close()
      } else {
        this.licensePlateToSearch = inputValue
        this.$refs.RightDrawer.open()
      }
    },
    goToMyTradeins() {
      this.$router.push('/min/byttebil')
    },
    relatedLoaded(e) {
      const total = e.detail[0]?.total
      if (total <= 1) this.noRelated = true
      this.relatedTotal = total
    },
    goToCar(e) {
      e.detail[0].preventDefault()
      const { item: listing } = e.detail[1]
      const brand = this.$root.slugify(listing.model.family.brand.name)
      const modelSlug = this.getModelSlug(listing)
      this.$router.push({ path: `/biler/${brand}/${modelSlug}/${listing.id}` })
    },
    showTradeInOffer(id) {
      let promiseResolve = null

      function closed() {
        document.body.querySelectorAll('seez-sdk-tradein-offer').forEach(x => x.parentNode.removeChild(x))
        if (promiseResolve) promiseResolve()
      }

      function checkListing(e) {
        if (e.detail[0].listing.id !== this.$route.params.id) closed()
      }

      const tradeinOfferComponent = document.createElement('seez-sdk-tradein-offer')
      tradeinOfferComponent.setAttribute('tradein', id)
      document.body.appendChild(tradeinOfferComponent)
      tradeinOfferComponent.addEventListener('loaded', checkListing.bind(this))
      tradeinOfferComponent.addEventListener('close', closed.bind(this))
      tradeinOfferComponent.addEventListener('buy', this.clickBuy)
      tradeinOfferComponent.addEventListener('buy', closed.bind(this))

      // eslint-disable-next-line no-unused-vars
      return new Promise(function (resolve, reject) {
        promiseResolve = resolve
      }, false)
    },
    leadModalTrackEvent(e) {
      if (e?.detail) {
        const event = e.detail[0]
        const eventName = event[0]
        const properties = event[1]
        this.$root.track(eventName, properties)
      }
    }
  }
}
</script>
<style lang="scss">
@import '../../styles/base.scss';

.invisible {
  opacity: 0;
}

.benefitsHeaderWrapper {
  padding-top: 5.5rem;

  @include respond(st-48) {
    padding-top: 0;
  }
}

seez-sdk-lead-form {
  .leadForm {
    padding: 0;
    margin: 0;

    .detail-showroom {
      display: none;
    }
    .formWrapper {
      padding: 0;
      box-shadow: none;

      .headingWrapper {
        border: none;
        margin: 0;
        padding: 0;
        padding-top: 1rem;

        .headingHidden {
          display: block;
        }
      }
      form {
        :nth-child(4) {
          display: none;
        }
        :nth-child(5) {
          grid-column: span 6 / span 6;
        }
        :nth-child(6) {
          grid-column: span 6 / span 6;
          @media screen and (min-width: 40rem) {
            grid-column: span 3 / span 3;
          }
        }
        :nth-child(7) {
          grid-column: span 6 / span 6;
          @media screen and (min-width: 40rem) {
            grid-column: span 3 / span 3;
          }
        }
      }
    }
  }
}

.loadingBackground {
  min-height: 100vh;
}

.listingContainer {
  min-height: 100vh;
  background-color: var(--var(--white));
  margin-top: 5.8rem;
  margin: auto;

  .customInfo {
    margin-top: 1.88rem;
  }

  @include respond(st-48) {
    margin-top: 0;
  }

  .layout {
    padding: 1rem;
    max-width: 80rem;
    margin: auto;
    display: grid;
    position: relative;
    overflow: visible;
    grid-template-columns: 1fr;

    @media screen and (min-width: 50rem) {
      grid-template-columns: 2fr 1fr;
      grid-template-areas:
        'imageWrapper rightContent'
        'cta cta'
        'mainDetailsWrapper mainDetailsWrapper'
        'dealerdescription dealerdescription'
        'specs specs'
        'tradeIn tradeIn'
        'videoBanner videoBanner'
        'benefits benefits'
        'calculator calculator'
        'related related';
    }

    @media screen and (min-width: 96rem) {
      margin-top: 2.438rem;
      max-width: 85rem;
    }

    .errorWrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .rightContent {
      overflow: visible;
      grid-area: rightContent; // Make sure you assign the area
    }
  }

  > .bottomSession {
    > div {
      margin: auto 1rem;
      @media screen and (min-width: 96rem) {
        max-width: 85rem;
        margin: auto;
      }
    }

    @media print {
      display: none;
    }

    .related {
      max-width: 68.2rem;
      margin: auto;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-bottom: 2rem;

      > h2 {
        padding: 0;
        margin: 3.5rem 0 1.875rem 0;
        color: var(--black);
        font-family: var(--font-heading);
        font-size: 2rem;
        font-weight: 100;

        @include respond(st-48) {
          font-size: 2.25rem;
        }
      }

      > a {
        border-radius: 12px;
        color: var(--white);
        background-color: var(--highlight);
        width: fit-content;
        padding: 0.75rem 3rem;
        margin-top: 1.25rem;
        font-weight: 700;
        cursor: pointer;
        text-decoration: none;
        font-family: var(--font-family);
        text-transform: uppercase;
      }
    }

    span > .calculator {
      padding: 1rem;
      border-radius: 16px;
      margin: 2rem auto;
      background-color: #f5f5f5;

      @include respond(st-48) {
        border-radius: 0;
      }

      > div {
        max-width: 80rem;
        margin: 0 auto;

        .calculatorParagraph {
          padding: 1rem 0 1.5rem 0;
        }
      }
    }
  }

  .listingDetails {
    color: var(--dark-gray-80);
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 50rem) {
      > .specs {
        > details {
          grid-template-columns: 1fr;
          gap: 1em;
          padding: 1em 0;

          > dl {
            grid-template-columns: 1fr 1fr;
            margin: 1em 0;

            > dt:nth-of-type(2n) {
              margin-left: 0;
            }

            > dd:nth-of-type(2n + 1) {
              margin-right: 0;
            }
          }

          > ul {
            grid-template-columns: 1fr;
            margin: 1em 0;
          }

          > div {
            margin: 0;
          }
        }
      }
    }

    @media print {
      display: none;
    }
  }
}

// start styles for trade-in
seez-sdk-trade-in {
  @media print {
    display: none;
  }

  .tradeInDrawerContainer {
    --highlight: #5bc2f4;

    .multiselect {
      > ul {
        left: -1rem;
      }
    }
    @mixin input-width {
      min-width: 10rem;
      max-width: 10rem;
    }

    * {
      font-family: var(--font-family) !important;
    }

    *input {
      border-radius: 12px !important;
    }

    *button {
      border-radius: 12px !important;
    }

    > button:last-of-type {
      background-color: #5bc2f4 !important;
    }

    .conditionDetails {
      border-radius: 12px;
    }

    .carDetails {
      display: grid;
      grid-template-columns: 1fr;
      gap: 14px;

      @include respond(st-48) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    .doubleColumn {
      grid-column-end: span 3;
    }

    .questionWrapper {
      @include respond(st-48) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .radio-btn-group {
        @include respond(st-48) {
          @include input-width;
        }
      }

      .carDetails input {
        @include respond(st-48) {
          @include input-width;
        }
      }

      .carDetails .multiselect {
        @include respond(st-48) {
          @include input-width;
        }
      }

      .multiselect {
        @include respond(st-48) {
          @include input-width;
        }
      }
    }

    .uploadSection {
      background: #f5f5f5 !important;
      border: 1px dashed black !important;
      cursor: pointer;

      svg {
        path {
          fill: black;
        }
      }

      label.tradeInImagesLabel {
        text-decoration: underline;
        color: #5bc2f4;
        cursor: pointer !important;
      }
    }

    .privacyBox {
      margin-top: 1rem;
      label {
        margin-inline-start: 0.3rem;
      }

      label:first-of-type {
        display: none;
      }
      label.customTermsLabel:before {
        content: 'Ja tak, jeg vil gerne holdes opdateret af STARMARK I/S via telefon, e-mail og SMS om biler, tilbehør og ydelser til biler, reparation/service af biler, events og konkurrencer STARMARK I/S må kontakte mig for udvidelse af samtykket. Du kan til enhver tid tilbagekalde dit samtykke på info@starmark.dk';
      }

      label.customPrivacyLabel {
        &:before {
          content: 'Jeg accepterer vilkårene ';
        }

        a {
          color: black;

          &:before {
            content: 'persondatapolitikken';
          }
        }
      }
    }

    .getTradeInValue {
      &:disabled {
        background: rgba(91, 194, 244, 0.5);
        cursor: not-allowed;
      }
    }
  }

  .tradeInDrawerContainer .radio-btn-group .radio label {
    border-radius: 12px !important;
  }

  .tradeInDrawerContainer > .relativePositioned > .tradeInTitle h2 {
    font-family: var(--font-heading) !important;
    font-style: normal;
    font-weight: 400;
    font-size: 2.188rem;
    line-height: 2.438rem;
  }

  .tradeInDrawerContainer .licensePlate {
    border-radius: 12px !important;
  }

  .tradeInDrawerContainer .licensePlate > span.prefix {
    border-radius: 12px 0 0 12px !important;
  }

  .tradeInDrawerContainer .licensePlate > button.search {
    border-radius: 10px;
  }
}
// end styles for trade-in

// start styles for tradein-help
seez-sdk-tradein-help {
  .tradeInUploadHelp {
    --highlight: #5bc2f4;
  }
}
// end styles for tradein-help

// start styles for seez-sdk-calculator
seez-sdk-calculator {
  --highlight: #5bc2f4;
  .calculatorWrapper {
    --highlight: #5bc2f4;
    * {
      font-family: var(--font-family) !important;
    }
  }

  .calculatorWrapper > .calculator > .leftContent > span > span {
    background-color: var(--highlight) !important;
  }
  .calculatorWrapper .calculator > .leftContent > label {
    font-weight: normal;
  }
  .calculatorWrapper .calculator > .leftContent > .loanDurationOptions > .active {
    border: 2px solid var(--highlight) !important;
    font-weight: 700;
    max-height: 2.8rem;
    color: var(--highlight) !important;
  }

  .calculatorWrapper .calculator > .leftContent > .checkboxMenu .active {
    border: 2px solid var(--highlight) !important;
  }

  .calculatorWrapper .calculator > .rightContent .blue-text {
    color: var(--highlight) !important;
  }

  .calculatorWrapper .loanDetailsHeader > .showLoanDetailsButton {
    color: var(--highlight) !important;
  }
}
// end styles for seez-sdk-calculator

// start styles for seez-sdk-loan-calculator
seez-sdk-loan-calculator {
  .loanCalculator {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5px;
    max-width: none;
    gap: 8px;

    > span {
      font-size: 1.875rem;
      font-weight: 700;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (min-width: 870px) {
        flex-direction: row;
      }

      > .loader {
        position: absolute;
        z-index: 5;
      }

      > .loanParameters {
        @media (min-width: 870px) {
          width: 55%;
        }

        display: flex;
        flex-direction: column;
        padding: 4px;
        gap: 16px;

        > .downPayment {
          order: 0;
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 1rem;

          > label {
            display: flex;
            flex-direction: column;
            position: relative;
            gap: 16px;

            > input {
              font-family: var(--font-family);
              border-radius: 72px;
              border: 1px solid var(--gray200);
              font-size: var(--large);
              font-weight: 600;
              padding: 14px;
            }

            > .currency {
              background-color: var(--highlight);
              border-radius: 0 24px 24px;
              color: white;
              font-size: 14px;
              display: flex;
              align-items: center;
              position: absolute;
              inset-inline-end: 0;
              padding: 1.05rem 1rem;
              right: 1.2px;
            }
          }

          > button {
            background-color: var(--white);
            color: var(--highlight);
            border-radius: 72px;
            border: 1px solid var(--highlight);
            padding: 16px 12px;
            cursor: pointer;
            width: 100%;
            font-weight: 700;
          }

          > .downPaymentExtraInfo {
            display: flex;
            font-size: 12px;
            gap: 0.5rem;
            padding: 0.2rem;
            color: rgb(117, 117, 117);

            > svg {
              width: 21px;
              height: 21px;
              fill: rgb(117, 117, 117);
            }
          }
        }

        > .paymentTerms {
          order: 2;

          > .paymentTermsExtraInfo {
            display: none;
          }
        }

        > .interestType {
          order: 3;
        }

        > .paymentTerms,
        .interestType {
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 1rem;

          > div {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            flex-wrap: wrap;
            gap: 8px;

            > button {
              border: none;
              padding: 14px 17px;
              cursor: pointer;
              border-radius: 6px;
              font-size: 14px;
              white-space: nowrap;
              font-weight: 400;
              display: flex;
              justify-content: center;
              align-items: center;
              max-height: 2.8rem;
              background-color: var(--white);
              font-family: var(--font-family);

              &.selected {
                border: 2px solid var(--highlight);
                font-size: 1rem;
                font-weight: 700;
                max-height: 2.8rem;
                color: var(--highlight);
              }
            }
          }
        }

        > button.saveCalculationButton {
          order: 4;
          font-family: var(--font-family);
          background-color: var(--red);
          color: var(--white);
          border-radius: 20px;
          border: none;
          padding: 16px 12px;
          cursor: pointer;
          width: 100%;
          margin-top: 12px;
          font-weight: 700;
        }

        > .calculationErrors {
          order: 1;
          color: var(--red);

          .calculationError {
            display: flex;
            flex-direction: row;
            font-size: 12px;
            gap: 0.5rem;
            padding: 0.2rem;
          }

          svg {
            path {
              fill: var(--red);
            }
          }
        }
      }

      > .loanOverview {
        @media (min-width: 870px) {
          width: 30%;
        }

        display: flex;
        position: relative;
        flex-direction: column;
        // overflow: hidden;
        border: 1px solid var(--gray200);
        border-radius: 1.5rem;
        background-color: var(--white);
        height: max-content;
        padding: 2rem;
        gap: 1rem;

        .details {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          color: var(--gray400);

          > .monthlyPayment {
            flex: 1 1 100%;

            > span:nth-of-type(2) {
              color: var(--highlight);
              font-size: 42px;
            }
          }

          > div {
            display: flex;
            flex: 1 1 25%;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            color: #545454;

            > span:nth-of-type(2) {
              color: black;
              font-weight: 700;
            }
          }

          > div:nth-last-child(2),
          div:nth-last-child(1) {
            flex: 1 1 100%;
          }

          > .downPayment,
          .aopBeforeTax,
          .totalPayable,
          .totalFinanceAmount,
          .rate {
            display: none;
          }

          > .santanderTitle {
            width: 100%;
            font-weight: 700;
            font-size: var(--large);
          }

          > .disclaimer {
            display: flex;
            flex-direction: column;
            color: var(--gray200);

            > span {
              font-size: 0px;
            }

            > p {
              font-size: 0.8rem;
              color: #757575;
              text-align: left;
            }
          }

          > .financingLogo {
            display: flex;
            flex-direction: row;
            align-items: start;

            span {
              font-weight: 700;
              font-size: 12px;
            }

            img {
              margin: 4px;
              height: 16px;
            }
          }
        }

        > .startOrderButton {
          display: none;
        }
      }
    }

    > .loanDetailsAccordion {
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      > .loanDetailsAccordionHeader {
        display: flex;
        justify-content: space-between;
        padding: 0px 16px;

        > span {
          font-size: 1rem;
          font-weight: 700;
        }

        > button {
          border: none;
          background: none;
          font-weight: 700;
          text-decoration: underline;
          color: var(--highlight);
        }
      }

      > .loanDetailsTable {
        display: grid;
        @media (min-width: 870px) {
          grid-template-columns: 1fr 1fr;
        }

        gap: 1rem;
        background-color: white;
        padding: 1.5rem;
        border-radius: 1rem;

        > div {
          display: none;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          border-top: 1px solid #eee;
          padding-top: 15px;

          > dd {
            font-weight: 700;
          }
        }

        > .aprDetail,
        .loanAmountDetail,
        .downPaymentPctDetail,
        .totalLoanCostDetail,
        .financedAmountDetail,
        .interestRateDetail,
        .nominalInterestRateDetail,
        .interestTypeDetail,
        .totalPayableDetail {
          display: flex;
        }

        > div:nth-child(1) {
          border: none;
          padding: 0px;
        }

        > div:nth-child(3) {
          @media (min-width: 870px) {
            border: none;
            padding: 0px;
          }
        }
      }
    }
  }
}
// end styles for seez-sdk-loan-calculator

// start styles for seez-sdk-carousel
seez-sdk-carousel {
  .listings > .listingCard {
    .name,
    .variant,
    .retail {
      text-align: left;
    }
    .prices {
      .emi,
      .lease {
        :nth-child(1) {
          text-align: left;
        }
      }
    }
    @include starmark-listing-card(var(--card-height), var(--card-financing-display), var(--card-leasing-display));
  }
}
// end styles for seez-sdk-carousel

// start styles for the seez-sdk-lead-modal
seez-sdk-lead-modal {
  @include starmark-lead-modal-style;
}
// end styles for seez-sdk-modal

// start styles for seez-sdk-active-order-cancellation
seez-sdk-active-order-cancellation {
  dialog > .cancelOrderDialog > .cta > button {
    border-radius: 12px;
    border: 1px solid #5bc2f4;
    font-family: var(--font-family);

    &:first-of-type {
      color: #5bc2f4;
    }

    &:last-of-type {
      background-color: #5bc2f4;
    }
  }
}
// end styles for seez-sdk-active-order-cancellation

// start styles for seez-test-drive-modal
seez-sdk-test-drive-modal {
  .seezModal {
    font-family: var(--font-family);
    background: #ffffff;
    border-radius: 0rem;
    max-width: 40rem;
    margin: auto;

    @include respond(st-48) {
      border-radius: 1rem;
    }

    .success {
      p {
        font-weight: 400;
      }

      button {
        background-color: var(--highlight);
        width: 100%;
        border-radius: 12px;
        padding: 0.75rem 1rem;
        color: white;
        border: none;
      }
    }

    > button {
      &:focus {
        outline: 0;
      }
    }

    > .testDriveModal {
      .testDriveNotice {
        text-align: left;
      }

      .headingText {
        border-bottom: 1px solid var(--light-gray);
        padding: 1rem 0;

        h2 {
          padding: 0;
          margin: 0 0 0.3rem 0;
          font-size: 2rem;
          font-family: var(--font-family);
          font-weight: normal;
          white-space: nowrap;
        }

        > div {
          white-space: nowrap;
          span:first-of-type {
            font-family: var(--font-family);
            font-weight: normal;
            font-size: 0.938rem;
          }
        }

        > div > span:nth-child(2) {
          font-size: 0.8rem;
          text-align: center;
          font-weight: normal;

          > svg {
            transform: translateY(0.1rem);
          }
        }
      }

      > div > .testDriveForm {
        p {
          margin: 0;
          padding: 0;
        }

        label {
          > span {
            display: none;
          }

          input[type='date'] {
            appearance: textfield;
            -moz-appearance: textfield;
            -webkit-appearance: none;
            color: black;
          }

          input {
            @include starmark-input-style;
            padding: 1rem 0.4rem 1rem 0;
          }

          @include black-placeholder;
        }

        .multiselectLabel {
          ul > li {
            font-family: var(--font-family);
          }
          > .multiselect {
            > div > button {
              background: #f5f5f5;
              border-radius: 0.875rem;
              padding: 1.05rem 0;
              border: none;
              text-indent: 0.625rem;
              font-size: 1rem;

              > span {
                font-family: var(--font-family);
              }

              > svg {
                transform: translateY(1rem);
              }
            }

            > div > ul {
              background-color: white;
            }
          }
        }

        .tradeInBtn {
          @include starmark-cta;
        }
      }

      .termsWrapper {
        .checkboxWrapper {
          display: flex;
          align-items: flex-start;
          margin-top: 1rem;
          font-size: 0.75rem;

          input {
            min-width: 5%;

            @include respond(st-48) {
              min-width: auto;
            }
          }

          label {
            margin-inline-start: 0.3rem;
          }

          label:first-of-type {
            display: none;
          }

          label.customTermsLabel:before {
            content: 'Ja tak, jeg vil gerne holdes opdateret af STARMARK I/S via telefon, e-mail og SMS om biler, tilbehør og ydelser til biler, reparation/service af biler, events og konkurrencer STARMARK I/S må kontakte mig for udvidelse af samtykket. Du kan til enhver tid tilbagekalde dit samtykke på info@starmark.dk';
          }

          label.customPolicyLabel:before {
            content: 'Jeg accepterer vilkårene for';
          }

          label.customPolicyLabel {
            a {
              color: black;
              &:before {
                content: ' persondatapolitikken';
              }
            }
          }
        }
      }

      .info {
        border-top: 1px solid var(--light-gray);

        p:first-of-type {
          display: none;
        }

        p {
          font-family: var(--font-family);
          font-style: normal;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.188rem;

          a {
            text-decoration: none;
            font-weight: 700;
          }
        }
      }
    }
  }
}
// end styles for seez-test-drive-modal

// start styles for seez-sdk-unavailable-listing-modal
seez-sdk-unavailable-listing-modal {
  .seezModal > div {
    --highlight: #5bc2f4;

    .buttonWrapper {
      button {
        background-color: var(--highlight) !important;
        color: var(--white) !important;
        border-radius: 12px !important;
        font-family: var(--font-family);
      }
    }

    .carousel .listingCard {
      @include starmark-listing-card(var(--card-height), var(--card-financing-display), var(--card-leasing-display));
      text-align: start;
    }

    svg > path {
      fill: var(--highlight);
    }
  }
}
// // end styles for seez-sdk-unavailable-listing-modal

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  min-height: 100vh;
}
</style>
